/*eslint no-unused-vars: ["error", { "destructuredArrayIgnorePattern": "^_" }]*/
import React from 'react';
import Select from 'react-select';
import { useField } from 'formik';

export const FormSelect = (props) => {
	const [field, _meta, helpers] = useField(props.field.name);

	return (
		<>
			<Select
				name={props.name}
				value={field.value}
				className={props.className}
				onChange={(value) => {
					helpers.setValue(value);
					props.onChange(value.value.toString());
				}}
				options={props.options}
				onBlur={() => helpers.setTouched(true)}
			/>
		</>
	);
};
