import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import VkSvg from 'assets/images/svg/icon_vk.svg';
import OkSvg from 'assets/images/svg/icon_ok.svg';
import { formatDate } from 'components/utils';
import { Loader } from 'components/loader/loader';
import Breadcrumbs from 'components/breadcrumbs';
import { MainLayout } from 'components/layout/mainLayout';
import axios from 'axios';
import TgSvg from 'assets/images/svg/tg.svg';
import { Link } from 'react-router-dom';

export const DetailNews = () => {
	const { id } = useParams();
	const [detailNews, setDetailNews] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
		axios
			.get(`${process.env.REACT_APP_NEWS_API}/news_list?id=${id}`)
			.then((data) => {
				setDetailNews(data);
				setLoading(false);
			});
	}, [id]);
	const { data } = detailNews;

	const shareVk =
		!loading &&
		`https://vk.com/share.php?url=${window.location.href}&title=${data.data[0].title}&image=${data.data[0].url_image}`;

	const shareOk =
		!loading &&
		`https://connect.ok.ru/offer?url=${window.location.href}&title=${data.data[0].title}&imageUrl=${data.data[0].url_image}`;

	const shareTg =
		!loading &&
		`https://t.me/share/url?url=${window.location.href}&text=${data.data[0].title}`;

	return (
		<MainLayout>
			<section className='text-page'>
				{loading ? (
					<Loader />
				) : (
					<div className='container text-page__container tab'>
						<div className='breadscrumb breadscrumb_dark text-page__breadscrumb'>
							<Breadcrumbs />
						</div>

						<div className='text-page__content'>
							<div className='text-page__top-block'>
								<div className='text-page__title-block'>
									<h1 className='text-page__title'>{data.data[0].title}</h1>
									<p className='text-page__date'>
										{data.data[0].date_created && (
											<b>{formatDate(data.data[0].date_created)}</b>
										)}
									</p>
								</div>
								<div className='text-page__social-list'>
									<a
										className='text-page__social-item'
										target={'_blank'}
										rel='noreferrer'
										href={shareVk}
									>
										<img src={VkSvg} alt={'VK'} />
									</a>
									<a
										className='text-page__social-item'
										href={shareTg}
										target={'_blank'}
										rel='noreferrer'
									>
										<img src={TgSvg} alt={'Twitter'} />
									</a>
									<a
										className='text-page__social-item'
										href={shareOk}
										target={'_blank'}
										rel='noreferrer'
									>
										<img src={OkSvg} alt={'VKontakte'} />
									</a>
								</div>
							</div>

							<img
								src={data.data[0].url_image}
								alt=''
								width='1120'
								height='630'
							/>

							<span>Источник фото: ER.RU</span>
							<h3>{data.data[0].announce}</h3>
							<p dangerouslySetInnerHTML={{ __html: data.data[0].body }}></p>

							<div className='text-page__hashtag'>
								{data.data[0].tags.split(',').map((e, i) => {
									const prepUrl = e.trim().replace('#', '');
									return e !== '' ? (
										<Link key={i} to={`/category/${prepUrl}`}>
											{e}
										</Link>
									) : null;
								})}
							</div>
						</div>
					</div>
				)}
			</section>
		</MainLayout>
	);
};
