import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { NavLink } from 'react-router-dom';
import IconLeftArrowWhite from 'assets/images/svg/icon_left-arrow-white.svg';
import IconLeftArrow from 'assets/images/svg/icon_left-arrow.svg';
const Breadcrumbs = ({ breadcrumbs }) => {
	return (
		<div className='breadscrumb'>
			<img
				src={
					breadcrumbs[0].location.pathname === '/login'
						? IconLeftArrowWhite
						: IconLeftArrow
				}
				alt={'ico'}
			/>
			{breadcrumbs.map(({ match, breadcrumb }) => {
				return (
					<NavLink
						key={match.url}
						className={match.url === '/' ? 'crumb_first' : 'crumb_item'}
						to={match.url}
					>
						{breadcrumb} <span>/&nbsp;</span>
					</NavLink>
				);
			})}
		</div>
	);
};

export default withBreadcrumbs(
	[
		{
			path: '/',
			breadcrumb: 'Главная',
		},
		{
			path: '/login',
			breadcrumb: 'Авторизация',
		},
	],
	{ excludePaths: ['/news', '/news/:id', '/category'] }
)(Breadcrumbs);
