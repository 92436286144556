// import { News } from 'components/news';
import { Link } from 'react-router-dom';
// import Slider from 'react-slick';
// import Slide1 from 'assets/images/slider1.jpg';
// import Slide2 from 'assets/images/slider2.jpg';
// import Slide3 from 'assets/images/slider3.jpg';
// import Slide4 from 'assets/images/slider4.jpg';
// import ArrowRight from 'assets/images/svg/arrow-right.svg';
import { MainLayout } from 'components/layout/mainLayout';

// function SamplePrevArrow(props) {
// 	const { className, style, onClick } = props;
// 	return (
// 		<div
// 			className={className}
// 			style={{ ...style, display: 'block' }}
// 			onClick={onClick}
// 		>
// 			<img src={ArrowRight} alt={'prev'} />
// 		</div>
// 	);
// }

// function SampleNextArrow(props) {
// 	const { className, style, onClick } = props;
// 	return (
// 		<div
// 			className={className}
// 			style={{ ...style, display: 'block' }}
// 			onClick={onClick}
// 		>
// 			<img src={ArrowRight} alt={'next'} />
// 		</div>
// 	);
// }

export const Main = () => {

	// const settings = {
	// 	dots: false,
	// 	infinite: true,
	// 	arrows: true,
	// 	speed: 500,
	// 	swipeToSlide: true,
	// 	slidesToShow: 2,
	// 	slidesToScroll: 1,
	// 	nextArrow: <SampleNextArrow />,
	// 	prevArrow: <SamplePrevArrow />,
	// 	responsive: [
	// 		{
	// 			breakpoint: 900,
	// 			settings: {
	// 				slidesToShow: 4,
	// 			},
	// 		},
	// 		{
	// 			breakpoint: 600,
	// 			settings: {
	// 				slidesToShow: 2,
	// 				slidesToScroll: 2,
	// 				initialSlide: 2,
	// 			},
	// 		},
	// 	],
	// };

	return (
		<MainLayout>
			<section className='main'>
				<div className='container'>
					<div className='main__top-block'>
						<h1 className='main__title'>Прямая связь</h1>
						<p className='main__caption'>
							Платформа для членов и сторонников «Единой России»
						</p>
					</div>

					<div className='main__bottom-block'>
						<div className='main__left-block'>
							<p className='main__text'>
								«Единая Россия» всегда на прямой связи с людьми, тем более на
								связи со своими избирателями и сторонниками. Реализация Народной
								программы, созданной на основе наказов миллионов жителей нашей
								страны — главная задача для Партии. Эту задачу нельзя выполнить
								в отрыве от граждан. Люди должны быть в курсе происходящего,
								иметь возможность из первых рук получать информацию о законах,
								принятых решениях и планах «Единой России», узнавать о
								деятельности своих депутатов, иметь возможность вносить свои
								предложения. На этом портале каждый может выбрать информационные
								сервисы по своим интересам и участвовать в большой работе партии
								Президента!
							</p>
								<Link to={'/login'}>
									{' '}
									<button className='main__btn button' type='button'>
										Присоединиться
									</button>
								</Link>
						</div>
						{/* <div className='main__right-block'>
							<Slider {...settings}>
								<div>
									<div className='main__slider-item'>
										<img src={Slide1} alt='' width='256' height='489' />
										<div className='main__text-block'>
											<p className='main__quotes'>
												Елена Ямпольская: Инициатива «Единой России» о нулевой
												ставке для ДК позволит повысить зарплаты сотрудникам
												культурно-досуговых учреждений
											</p>
											<p className='main__date-block'>
												<span className='main__date'>14 января 2022</span>
												<span className='main__time'>11:01</span>
											</p>
										</div>
									</div>
								</div>
								<div>
									<div className='main__slider-item'>
										<img src={Slide2} alt='' width='256' height='489' />
										<div className='main__text-block'>
											<p className='main__quotes'>
												Евгений Ревенко: В вопросе о признании Луганской и
												Донецкой Народных республик депутаты всех фракций
												продемонстрировали единодушие
											</p>
											<p className='main__date-block'>
												<span className='main__date'>14 января 2022</span>
												<span className='main__time'>11:01</span>
											</p>
										</div>
									</div>
								</div>
								<div>
									<div className='main__slider-item'>
										<img src={Slide3} alt='' width='256' height='489' />
										<div className='main__text-block'>
											<p className='main__quotes'>
												Ирина Яровая: «Единая Россия» предлагает ввести
												дополнительные меры по защите жизни и безопасности детей
											</p>
											<p className='main__date-block'>
												<span className='main__date'>14 января 2022</span>
												<span className='main__time'>11:01</span>
											</p>
										</div>
									</div>
								</div>
								<div>
									<div className='main__slider-item'>
										<img src={Slide4} alt='' width='256' height='489' />
										<div className='main__text-block'>
											<p className='main__quotes'>
												Александр Якубовский: «Единая Россия» держит на контроле
												исполнение регионами обязательств по восстановлению прав
												обманутых дольщиков
											</p>
											<p className='main__date-block'>
												<span className='main__date'>14 января 2022</span>
												<span className='main__time'>11:01</span>
											</p>
										</div>
									</div>
								</div>
							</Slider>
						</div> */}
					</div>
				</div>
			</section>
			{/* <News /> */}
		</MainLayout>
	);
};
