import VkSvg from 'assets/images/svg/icon_vk-dark.svg';
import OkSvg from 'assets/images/svg/icon_ok-dark.svg';
import ArrowUp from 'assets/images/svg/icon_arrow-up-white.svg';
import { scroll } from 'components/utils';

export const Footer = () => {
	return (
		<footer className='footer footer_dark'>
			<div className='container footer__container'>
				<div className='footer__social-list'>
					<a className='footer__social-item' href='https://vk.com/er_ru'>
						<img src={VkSvg} className='footer__icon-vk' alt='VK' />
					</a>
					<a className='footer__social-item' href='https://ok.ru/ernovosti'>
						<img src={OkSvg} className='footer__icon-vk' alt='VK' />
					</a>
				</div>
				<p className='footer__text'>
					Прямая связь. Платформа сторонников Единой России<br></br>
					©2015-2022, Партия «Единая Россия».<br></br>
					Все права защищены. <a href='https://er.ru/upages/privacy-policy' rel="noreferrer" target={'_blank'}>Политика конфиденциальности</a>
				</p>
				<p className='footer__desc'>
					Продолжая находиться на данном сайте, вы соглашаетесь на
					предоставление информации об ip-адресе, имени и стране домена
					провайдера, переходах с одной страницы на другую и cookies.
				</p>
				<button
					className='footer__btn js-scroll-to-top'
					type='button'
					onClick={() => scroll('#header')}
				>
					Наверх
					<img src={ArrowUp} className='footer__arrow-up' alt='icon' />
				</button>
			</div>
		</footer>
	);
};
