import { useEffect, useState } from 'react';
import { NewsCardBig } from 'components/news/newsCardBig';
import { NewsCardSmall } from 'components/news/newsCardSmall';
import ReactPaginate from 'react-paginate';
import { Loader } from 'components/loader/loader';
import axios from 'axios';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { MainLayout } from 'components/layout/mainLayout';
import Breadcrumbs from 'components/breadcrumbs';
export const Category = () => {
	const { id } = useParams();
	const [news, setNews] = useState([]);
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(0);
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		history.location.search === '' && history.push('?page=1');
	});

	useEffect(() => {
		const getParam = location.search.replace('?page=', '');

		axios
			.get(
				`${
					process.env.REACT_APP_NEWS_API
				}/news_list?regions=null&per_page=20&tags=%22%23${encodeURI(
					id
				)}%22&page=${getParam}`
			)
			.then((data) => {
				setNews(data.data);
				setPage(getParam);
				setLoading(false);
			});
	}, [location, id]);

	const renderBlocks = () => {
		if (!loading && news.data && page <= 1) {
			return news.data.map((newsItem, index) => {
				return index <= 5 ? (
					<NewsCardBig key={newsItem.id} news={newsItem} />
				) : (
					<NewsCardSmall key={newsItem.id} news={newsItem} />
				);
			});
		}
		if (!loading && news.data && page > 1) {
			return news.data.map((newsItem) => {
				return <NewsCardSmall key={newsItem.id} news={newsItem} />;
			});
		}
	};

	const handlePageClick = ({ selected }) => {
		setPage(selected + 1);
		setLoading(true);
		history.push(`?page=${selected + 1}`);
	};
	return (
		<MainLayout>
			<section className='news'>
				<div className='container'>
					<div className='breadscrumb breadscrumb_dark text-page__breadscrumb'>
						<Breadcrumbs />
					</div>
					<h1 class='news__title'>#{id}</h1>
					{loading ? (
						<Loader />
					) : (
						<div className='news__list'>{renderBlocks()}</div>
					)}
					{news?.meta?.last_page > 1 && (
						<ReactPaginate
							breakLabel='...'
							nextLabel='>'
							pageRangeDisplayed={3}
							pageCount={news.meta.last_page}
							forcePage={page - 1}
							disableInitialCallback={true}
							onPageChange={handlePageClick}
							previousLabel='<'
							renderOnZeroPageCount={null}
							containerClassName={'pagination'}
						/>
					)}
				</div>
			</section>
		</MainLayout>
	);
};
