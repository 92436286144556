export const formatDate = (dateString) => {
	return new Date(dateString).toLocaleString('ru', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		timezone: 'UTC',
		hour: 'numeric',
		minute: 'numeric',
	});
};

export const formatDate2 = (dateString) => {
	return new Date(dateString).toLocaleString('ru', {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	});
};

export const scroll = (selector) => {
	const section = document.querySelector(`${selector}`);
	return section.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

export const checkWindow = (maxWidthPixel) =>
	typeof window !== 'undefined' &&
	window.matchMedia(`(max-width: ${maxWidthPixel}px)`).matches;

export const isMobile = checkWindow(1024);

export const isPhone = checkWindow(768);
