import { Link } from 'react-router-dom';
import { formatDate } from 'components/utils';

export const NewsCardSmall = ({ news }) => {
	const prepUrl = news.tags.split(',')[0].trim().replace('#', '');
	return (
		<div className='news__item'>
			<Link to={`/news/${news.id}`} className='new new_small'>
				<div className='new__img-block'>
					<img
						src={news.url_thumbnail}
						alt=''
						width='96'
						height='96'
						loading='lazy'
					/>
				</div>
				<div className='new__text-block'>
					<p className='new__text'>{news.title}</p>
					<Link key={news.id} className='new__tag' to={`/category/${prepUrl}`}>
						{news.tags.split(',')[0]}
					</Link>
					<span className='new__date'>{formatDate(news.date_created)}</span>
				</div>
			</Link>
		</div>
	);
};
