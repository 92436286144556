import Logo from 'assets/images/logo.svg';
import { Link } from 'react-router-dom';
import Breadcrumbs from 'components/breadcrumbs';

export const Header = () => {
	return (
		<header className='authorization'>
			<div className='authorization__container'>
				<Link className='authorization__link' to={'/'}>
					<img src={Logo} alt='' width='111' height='28' />
				</Link>
				<h1 className='authorization__title'>Прямая связь</h1>
				<p className='authorization__caption'>
					Платформа сторонников Единой России
				</p>

				<div className='breadscrumb authorization__breadscrumb'>
					<Breadcrumbs />
				</div>
			</div>
		</header>
	);
};
