import { useAuthState } from 'context';
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { successToast, errorToast } from 'components/notifications';

export const Communication = ({ data }) => {
	const { userDetails, token } = useAuthState();

	const initial = userDetails.communications.reduce(
		(map, id) => ({
			...map,
			[id.code]: true,
		}),
		{}
	);

	return (
		<Formik
			initialValues={initial}
			onSubmit={({ data, ...values }, { setSubmitting }) => {
				const communications = Object.keys(values).filter((e) => {
					return values[e] ? e : null;
				});
				axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
				axios
					.post(`${process.env.REACT_APP_BACKEND}/user/communications`, {
						communications,
					})
					.then(() => successToast())
					.catch(() => errorToast());
			}}
		>
			{({ dirty }) => (
				<Form className='profile__form js-registration-form'>
					{data.data?.map((e) => {
						return (
							<div key={e.id} className='profile__checkbox-block'>
								<Field id={e.code} name={e.code} type='checkbox' />
								<label htmlFor={e.code}>{e.name}</label>
							</div>
						);
					})}
					<div className='profile__btn-block'>
						<button
							disabled={!dirty}
							className='profile__btn-submit button'
							type='submit'
						>
							Сохранить
						</button>
						<ToastContainer />
					</div>
				</Form>
			)}
		</Formik>
	);
};
