import { Header } from './header';
import { Footer } from './footer';

export const AuthLayout = ({ children }) => {
	return (
		<div className='bg-dark'>
			<div style={{ flexGrow: '1' }}>
				<Header />
				<main>{children}</main>
			</div>
			<Footer />
		</div>
	);
};
