import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useEffect } from 'react';
import { useAuthState } from 'context';
import { MainLayout } from 'components/layout/mainLayout';
import axios from 'axios';
import { useState } from 'react';
import { Loader } from 'components/loader/loader';
import { Subscriptions } from 'components/forms/profile/subscriptions';
import { PersonalDetails } from 'components/forms/profile/personalDetails';
import { updateUser } from 'context/actions';
import { useAuthDispatch } from 'context';
import { Communication } from 'components/forms/profile/communication';
import { isMobile } from 'components/utils';

export const Profile = () => {
	const { token } = useAuthState();
	const [subscription, setSubscription] = useState({});
	const [communications, setCommunications] = useState([]);
	const [loading, setLoading] = useState(true);
	const [region, setRegion] = useState([]);
	const dispatch = useAuthDispatch();

	useEffect(() => {
		axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
		axios.get(`${process.env.REACT_APP_BACKEND}/user/profile`).then((data) => {
			updateUser(dispatch, data).then(() => setLoading(false));
		});
	}, [token, dispatch]);

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_BACKEND}/region/list`)
			.then((data) => setRegion(data.data));
	}, []);

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_BACKEND}/subscription/list`)
			.then((data) => {
				setSubscription({ ...data.data });
			});
	}, []);

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_BACKEND}/communication/list`)
			.then((data) => {
				setCommunications({ ...data.data });
			});
	}, []);

	return (
		<MainLayout>
			{loading ? (
				<Loader />
			) : isMobile ? (
				<section className='profile'>
					<div className='container profile__container tab'>
						<Tabs selectedTabClassName='active'>
							<TabList className='profile__tabs'>
								<Tab className='profile__tab'>Темы</Tab>
								<Tab className='profile__tab'>Профиль</Tab>
								<Tab className='profile__tab'>Уведомления</Tab>
							</TabList>

							<TabPanel>
								<div id='content1' className='info'>
									<h3 className='profile__title'>
										Какую информацию вы хотите получать от «Единой России»?
									</h3>
									<Subscriptions data={subscription.data} />
								</div>
							</TabPanel>
							<TabPanel>
								<div id='content2' className='perosnal'>
									<h3 className='profile__title'>Личные данные</h3>
									<PersonalDetails region={region} loading={loading} />
								</div>
							</TabPanel>
							<TabPanel>
								<div id='content3' className='communication'>
									<h3 className='profile__title'>
										Предпочтительные способы коммуникации
									</h3>
									<Communication data={communications} />
								</div>
							</TabPanel>
						</Tabs>
					</div>
				</section>
			) : (
				<section className='profile'>
					<div className='container profile__container tab'>
						<div className='profile__block'>
							<div className='profile__list'>
								<div id='content1' className='info'>
									<h3 className='profile__title'>
										Какую информацию вы хотите получать от «Единой России»?
									</h3>
									<Subscriptions data={subscription.data} />
								</div>
								<div id='content2' className='perosnal'>
									<h3 className='profile__title'>Личные данные</h3>
									<PersonalDetails loading={loading} region={region} />
								</div>
								<div id='content3' className='communication'>
									<h3 className='profile__title'>
										Предпочтительные способы коммуникации
									</h3>
									<Communication data={communications} />
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
		</MainLayout>
	);
};
