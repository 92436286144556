import { Formik, Form, Field } from 'formik';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuthState } from 'context';
import { ToastContainer } from 'react-toastify';
import { successToast, errorToast } from 'components/notifications';

export const Subscriptions = ({ data }) => {
	const [state, setState] = useState([]);
	const { userDetails, token } = useAuthState();
	useEffect(() => {
		setState(data);
	}, [data]);

	useEffect(() => {
		userDetails.subscriptions?.map((e) => {
			if (e.id === 28) {
				return setState(
					data.map((e, i) => {
						if (i < 27) {
							return { ...e, is_enabled: false };
						}
						return { ...e };
					})
				);
			}
			return setState(data);
		});
	}, [data, userDetails]);

	const initialData = userDetails.subscriptions.reduce(
		(map, id) => ({
			...map,
			[id.id]: true,
		}),
		{}
	);

	return (
		<Formik
			initialValues={initialData}
			validate={(values) => {
				if (values[28] === true) {
					return setState(
						data.map((e, i) => {
							if (i < 27) {
								return { ...e, is_enabled: false };
							}
							return { ...e };
						})
					);
				}
				return setState(data);
			}}
			onSubmit={({ data, ...values }) => {
				const subscriptions =
					values[28] === true
						? [28]
						: Object.keys(values).filter((e) => {
								return values[e] && parseInt(e);
						  });
				axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
				axios
					.post(`${process.env.REACT_APP_BACKEND}/user/subscribe`, {
						subscriptions,
					})
					.then(() => successToast())
					.catch(() => errorToast());
			}}
		>
			{({ dirty }) => (
				<Form className='profile__form js-registration-form'>
					{state.map((e) => {
						return (
							<div key={e.id} className='profile__checkbox-block'>
								<Field
									id={e.id}
									name={e.id}
									type='checkbox'
									disabled={!e.is_enabled}
								/>
								<label htmlFor={e.id}>{e.name}</label>
							</div>
						);
					})}
					<div className='profile__btn-block'>
						<button
							className='profile__btn-submit button'
							disabled={!dirty}
							type='submit'
						>
							Сохранить
						</button>
						<ToastContainer />
					</div>
				</Form>
			)}
		</Formik>
	);
};
