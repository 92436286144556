import { useState, useCallback } from 'react';
import Phone from 'assets/images/svg/icon_phone.svg';
import { Formik, Form } from 'formik';
import { InputPhone } from 'components/inputs/phoneInput';
import { AuthLayout } from 'components/layout/authLayout';
import axios from 'axios';
import { validationPhone } from '../validation';
import { SmartCaptcha } from '@yandex/smart-captcha';
export const PhoneStep = ({
  nextStep,
  setPhone,
  setIsnew,
  setExpiredIn,
  setSmsError,
}) => {
  const [token, setToken] = useState(null)
  const [visible, setVisible] = useState(false)
  const handleChallengeHidden = useCallback(() => setVisible(false), [])

  return (
    <AuthLayout>
      <section className='authorization'>
        <div className='authorization__container'>
          <div className='authorization__step1'>
            <p className='authorization__desc'>
              Пожалуйста, введите свой мобильный телефон — Вам будет отправлен
              одноразовый пароль для входа.
            </p>
            <Formik
              initialValues={{ phone: '' }}
              validate={(values) => {
                setVisible(true)
                return validationPhone(values);
              }}
              onSubmit={async (values) => {
                const prepPhone = values.phone
                  .replaceAll(/[+()\-\s]/g, '')
                  .substring(1);
                return axios
                  .post(`${process.env.REACT_APP_BACKEND}/auth/authenticate`, {
                    phone: prepPhone,
                    captcha_token: token
                  })
                  .then((data) => {
                    if (data.data.expired) {
                      setExpiredIn(data.data.expired);
                      setSmsError(true);
                    }
                    setPhone(prepPhone);
                    nextStep();
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                setFieldValue,
              }) => (
                <Form
                  className='authorization__form authorization__form_2 js-authorization-form'
                  onSubmit={handleSubmit}
                >
                  <div className='authorization__input-block'>
                    <label htmlFor='phone'>Номер телефона*</label>
                    <InputPhone
                      className={errors.phone ? 'input error' : 'input'}
                      onChange={(e) => {
                        if (e.target.value[4] !== '9')
                          return setFieldValue('phone', true);
                        return setFieldValue('phone', e.target.value);
                      }}
                      onBlur={handleBlur}
                      value={values.phone}
                    />
                    <img src={Phone} alt='phone' />
                    <span className='authorization__error'>
                      {errors.phone && touched.phone && errors.phone}
                    </span>
                  </div>
                  <div>
                    <button
                      className='authorization__btn-submit button'
                      type='submit'
                      disabled={!isValid || !token}
                    >
                      Получить смс
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div style={{ maxWidth: '400px', marginTop: '30px' }}>
            <SmartCaptcha
              sitekey={'ysc1_ofoiGrh1fwiCEDbOiuYDKohvY3XerdgQJFS42E8D85dbfb6f'}
              onSuccess={setToken}
              onChallengeHidden={handleChallengeHidden}
              visible={visible}
              hideShield={true}
            />

          </div>
        </div>
      </section>
    </AuthLayout>
  );
};
