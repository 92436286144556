import { Link } from 'react-router-dom';
import { formatDate } from 'components/utils';
export const NewsCardBig = ({ news }) => {
	return (
		<div className='news__item'>
			<Link to={`/news/${news.id}`} className='new new_big'>
				<div className='new__img-block'>
					<img
						src={news.url_image}
						alt=''
						width='544'
						height='275'
						loading='lazy'
					/>
				</div>
				<div className='new__text-block'>
					<p className='new__text'>{news.title}</p>
					<span className='new__date'>
						<b>{formatDate(news.date_created)}</b>
					</span>
				</div>
			</Link>
		</div>
	);
};
