import { Main } from 'pages/main';
import { DetailNews } from 'templates/detailedNew';
import { Login } from 'pages/login/';
import { Profile } from 'pages/profile';
import { Category } from 'pages/category';
export const routes = [
	{
		path: '/login',
		component: Login,
		isPrivate: false,
		breadcrumb: 'Авторизация',
	},
	{
		path: '/news',
		component: Main,
		isPrivate: false,
		breadcrumb: null,
	},
	{
		path: '/',
		component: Main,
		isPrivate: false,
		breadcrumb: 'Главная',
	},
	{
		path: '/news/:id',
		component: DetailNews,
		isPrivate: false,
		breadcrumb: null,
	},
	{
		path: '/profile',
		component: Profile,
		isPrivate: true,
		breadcrumb: 'Профиль',
	},
	{
		path: '/category/:id',
		component: Category,
		isPrivate: false,
		breadcrumb: 'id',
	},
	{
		path: '/category',
		component: Category,
		isPrivate: false,
		breadcrumb: null,
	},
];
