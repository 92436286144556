import { useEffect, useState } from 'react';
import { InputPhone } from 'components/inputs/phoneInput';
import { FormSelect } from 'components/inputs/selectField';
import { DateInput } from 'components/inputs/birthdayInput';
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import { useAuthState } from 'context';
import { validationProfile2 } from 'components/forms/validation';
import { formatDate2 } from 'components/utils';
import { updateUser } from 'context/actions';
import { useAuthDispatch } from 'context';
import { ToastContainer } from 'react-toastify';
import { successToast, errorToast } from 'components/notifications';

export const PersonalDetails = ({ region, loading }) => {
	const { userDetails, token } = useAuthState();
	const [date, setDate] = useState(new Date(userDetails.birthday));
	const [selectedRegionOption, setSelectedRegionOption] = useState(
		userDetails.district.region.id
	);
	const [selectedDisctrictOption, setSelectedDisctrictOption] = useState(null);
	const dispatch = useAuthDispatch();
	useEffect(() => {
		axios
			.get(
				`${process.env.REACT_APP_BACKEND}/district/list/${selectedRegionOption}`
			)
			.then((data) => {
				setSelectedDisctrictOption(data.data.data);
			});
	}, [userDetails.district.id, selectedRegionOption]);

	return (
		<>
			{!loading && (
				<Formik
					initialValues={{
						...userDetails,
						region_id: {
							value: userDetails.district.region.id,
							label: userDetails.district.region.name,
						},
						district_id: {
							value: userDetails.district.id,
							label: userDetails.district.name,
						},
					}}
					validate={(values) => {
						return validationProfile2(values);
					}}
					onSubmit={({ data, ...values }, { setSubmitting }) => {
						axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
						const prepData = {
							surname: values.surname,
							name: values.name,
							patronymic: values.patronymic,
							birthday: formatDate2(values.birthday),
							region_id: values.region_id.value,
							district_id: values.district_id.value,
							email: values.email,
						};
						axios
							.post(`${process.env.REACT_APP_BACKEND}/user/profile`, {
								...prepData,
							})
							.then((data) => {
								updateUser(dispatch, data);
								successToast();
							})
							.catch((e) => errorToast());
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						dirty,
						isValid,
						setFieldValue,
					}) => (
						<Form className='profile__form js-registration-form'>
							<div className='profile__input-block'>
								<label htmlFor='surname'>Фамилия*</label>

								<Field
									className={errors.surname ? 'input error' : 'input'}
									onChange={handleChange}
									value={values.surname}
									id='surname'
									name='surname'
									type='text'
									placeholder='Фамилия'
								/>
								<span className='authorization__error'>
									{errors.surname && touched.surname && errors.surname}
								</span>
							</div>
							<div className='profile__input-block'>
								<label htmlFor='name'>Имя*</label>

								<Field
									className={errors.name ? 'input error' : 'input'}
									onChange={handleChange}
									value={values.name}
									id='name'
									name='name'
									type='text'
									placeholder='Имя'
								/>
								<span className='authorization__error'>
									{errors.name && touched.name && errors.name}
								</span>
							</div>
							<div className='profile__input-block'>
								<label htmlFor='patronymic'>Отчество</label>

								<Field
									className='input'
									onChange={handleChange}
									value={values.patronymic}
									id='patronymic'
									name='patronymic'
									type='text'
									placeholder='Отчество'
								/>
							</div>
							<div className='profile__input-block'>
								<label htmlFor='birthday'>Дата рождения*</label>

								<Field
									className={
										errors.birthday
											? 'input js-datapicker error'
											: 'input js-datapicker'
									}
									id='birthday'
									component={DateInput}
									clearIcon={null}
									calendarIcon={null}
									monthPlaceholder={'ММ'}
									dayPlaceholder={'ДД'}
									yearPlaceholder={'ГГГГ'}
									onChange={setDate}
									value={date}
									name='birthday'
									type='text'
									placeholder='ДД.ММ.ГГГГ'
								/>
								<span className='authorization__error'>
									{errors.birthday && touched.birthday && errors.birthday}
								</span>
							</div>
							<div className='profile__input-block'>
								<label htmlFor='phone'>Номер телефона*</label>

								<InputPhone
									onChange={handleChange}
									onBlur={handleBlur}
									readOnly
									disabled={true}
									value={values.phone}
								/>
							</div>
							<div className='profile__input-block'>
								<label htmlFor='email'>Адрес электронной почты*</label>

								<Field
									className={errors.email ? 'input error' : 'input'}
									id='email'
									name='email'
									onChange={handleChange}
									value={values.email}
									type='text'
									placeholder='Email'
								/>
								<span className='authorization__error'>
									{errors.email && touched.email && errors.email}
								</span>
							</div>
							<div className='profile__input-block'>
								<label>Регион*</label>
								<Field
									name='region_id'
									className={errors.region_id ? 'error' : ''}
									component={FormSelect}
									value={selectedRegionOption}
									onChange={(e) => {
										setSelectedRegionOption(e);
										setFieldValue('district_id', 0);
									}}
									options={region?.data?.map((e) => {
										return { value: e.id, label: e.name };
									})}
								/>
								<span className='authorization__error'>
									{errors.region_id && touched.region_id && errors.region_id}
								</span>
							</div>
							<div className='profile__input-block'>
								<label htmlFor='district_id'>Район*</label>
								<Field
									name='district_id'
									className={errors.district_id ? 'error' : ''}
									component={FormSelect}
									options={selectedDisctrictOption?.map((e) => {
										return { value: e.id, label: e.name };
									})}
									value={selectedDisctrictOption}
									onChange={handleChange}
								/>
								<span className='authorization__error'>
									{errors.district_id &&
										touched.district_id &&
										errors.district_id}
								</span>
							</div>

							<div className='profile__input-block'>
								<label htmlFor='address'>Населенный пункт*</label>

								<Field
									className={errors.address ? 'input error' : 'input'}
									id='address'
									onChange={handleChange}
									value={values.address}
									name='address'
									type='text'
									placeholder='Населенный пункт'
								/>
								<span className='authorization__error'>
									{errors.address && touched.address && errors.address}
								</span>
							</div>

							<div className='profile__btn-block'>
								<button
									disabled={!dirty}
									className='profile__btn-submit button'
									type='submit'
								>
									Сохранить
								</button>
							</div>
							<ToastContainer />
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};
