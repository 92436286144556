import { Header } from './header';
import { Footer } from './footer';

export const MainLayout = ({ children }) => {
	return (
		<>
			<Header />
			<main>{children}</main>
			<Footer />
		</>
	);
};
